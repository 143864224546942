import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/layout.js";
import SEO from "../components/seo";
import MeldungPreview from "../components/MeldungPreview";

const Aktuelles = ({ data }) => {
  const people = data.allStrapiPeople;
  return (
    <Layout>
      <SEO
        keywords={[`i-em`, `bayreuth`, `über uns`, `brink`]}
        title="Über Uns"
      />
      <section id="hero">
        <div className="container mx-auto">
          <div className="text-center my-8 mt-12">
            <h1 className="font-header text-3xl">Aktuelles</h1>
            <p>Meldungen rund um das iLab.</p>
          </div>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8 mb-12">
            {data.allWpPost.edges.map(({ node }) => (
              <MeldungPreview node={node} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPost(
      filter: { status: { eq: "publish" } }
      sort: { fields: date, order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "DD.MM.YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Aktuelles;
